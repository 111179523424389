/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class ItemTrackingComponent
 * @description
 * Ticket Tracker Component
 * Created by Carlos.Moreira @ 2019/07/02
 */
// Angular Components
import { Component, OnInit, OnDestroy, Injector, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';

// Ticket Tracker Components
import { TicketTrackerService } from '../../../core/services/ticket-tracker.service';
import { TicketTrackerErrors } from '../../../core/enums/errors/ticket-tracker-errors.interface';
import { TicketTrackerStatus } from '../../../core/enums/state/ticket-tracker-status.interface';
import { TicketTrackerState } from '../../../core/models/state/ticket-tracker-state.interface';

// Libraries Components
import { LoggerService, LanguageService } from 'qevo.services';
import { EntityAppsSettings, TicketTrackerSettings, RecordStatus, EtsDisplayType } from 'qore.models';
import { isNullOrUndefined, NumberUtilities } from 'qevo.utilities';
import { ServiceTypeEnum } from 'qevo.models';
import { BookingDetails, BookingStatusEnum } from 'qore.bookings.models';

@Component({
	selector: 'qoala-tt-item-tracking',
	templateUrl: './item-tracking.component.html',
	styleUrls: ['./item-tracking.component.scss'],
	encapsulation: ViewEncapsulation.None // Disable CSS encapsulation
})
export class ItemTrackingComponent implements OnInit, OnDestroy {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */
	// Component
	protected componentName: string;

	// Get it here within intercept
	protected _router: Router;
	protected _activatedRoute: ActivatedRoute;
	protected _logger: LoggerService;
	protected _ticketTrackerService: TicketTrackerService;
	protected _languageService: LanguageService;

	// Router Subscription
	private _routerSub: Subscription;

	// Is Sound Active Subscription
	private _isSoundActiveSub: Subscription;

	/**
	 * ================================================================================================================================
	 * Languages - Start
	 * ================================================================================================================================
	 */

	// Language Change Subscription
	private _onLanguageChangeSub: Subscription;

	// Current language
	curLangCode: string;

	/**
	 * ================================================================================================================================
	 * Languages - End
	 * ================================================================================================================================
	 */

	/**
	 * ================================================================================================================================
	 * Ticket Tracker State - Start
	 * ================================================================================================================================
	 */

	// Ticket Tracker State subscription
	private _ticketTrackerStateSub: Subscription;

	// Current Ticket Tracker State
	private _currentState: TicketTrackerStatus;

	public get currentState(): TicketTrackerStatus {
		return isNullOrUndefined(this._currentState) ? TicketTrackerStatus.StartItemTracking : this._currentState;
	}

	/**
	 * ================================================================================================================================
	 * Ticket Tracker State - End
	 * ================================================================================================================================
	 */

	// Indicates that the call is called
	public isItemCalled: boolean;

	// Show counter in calling panels flag
	public showCounterInCallingPanels: boolean;

	// Show ETS in ticket flag (entity override)
	public showETS: EtsDisplayType;

	/**
	 * ================================================================================================================================
	 * Customizations - Start
	 * ================================================================================================================================
	 */
	// Customization's stylesheet Url
	// 	- Use the template.folder property adding 'assets/customizations/' at the beginning
	// 	- folder property should be a list separated by "," of "folder + file of scss or css"
	// 		ex:
	// 			- client-1/client.css
	// 			- client-1/client.css, client-1/client-second-file.css
	public customizationStylesheetUrls: SafeResourceUrl[];

	/**
	 * ================================================================================================================================
	 * Customizations - End
	 * ================================================================================================================================
	 */

	// Scroll (UI view mode)
	public isScrollingUp = true;

	// Allow scroll
	isScrollAllowed = true;

	// Sound
	isSoundActive = false;

	// Version Information and Date
	versionInfo: string;
	versionDate: string;
	showVersionDate: boolean;

	// Touch
	private _touchStart: number;
	private _touchEnd: number;
	private _stopTouchEvents: boolean;

	// UI function exports
	isNullOrUndefined: typeof isNullOrUndefined = isNullOrUndefined;
	TicketTrackerStatus: typeof TicketTrackerStatus = TicketTrackerStatus;

	// Hide Calling Area
	hideCallingArea: boolean;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(private _injector: Injector) {
		// Gets the components name
		this.componentName = 'ItemTrackingComponent';

		// Get services here with interceptor
		// Angular
		this._router = this._injector.get(Router);
		this._activatedRoute = this._injector.get(ActivatedRoute);

		// Ticket Tracker
		this._logger = this._injector.get(LoggerService);
		this._ticketTrackerService = this._injector.get(TicketTrackerService);
		this._languageService = this._injector.get(LanguageService);
	}

	ngOnInit() {
		// Watch for language changes
		this._onLanguageChangeSub = this._languageService.onLanguageChange$.subscribe(language => {
			this.curLangCode = language.code;
		});

		// Watch for is sound active changes
		this._isSoundActiveSub = this._ticketTrackerService.isSoundActive$.subscribe(isActive => {
			this.isSoundActive = isActive;
		});

		// Get Configurations
		this.getCardInformation();
	}

	ngOnDestroy() {
		// Unsubscribe
		if (!isNullOrUndefined(this._routerSub)) { this._routerSub.unsubscribe(); }
		if (!isNullOrUndefined(this._ticketTrackerStateSub)) { this._ticketTrackerStateSub.unsubscribe(); }
		if (!isNullOrUndefined(this._onLanguageChangeSub)) { this._onLanguageChangeSub.unsubscribe(); }
		if (!isNullOrUndefined(this._isSoundActiveSub)) { this._isSoundActiveSub.unsubscribe(); }
	}

	/**
	 * ********************************************************************************************************************************
	 * Methods
	 * ********************************************************************************************************************************
	 */

	/**
	 * Store touch start position on screen
	 * @param event event data
	 */
	storeTouchStart(event: any) {
		if (event.changedTouches.length === 0) { return; }

		this._touchStart = event.changedTouches[0].screenY;
	}

	/**
	 * Store touch end position on screen
	 * @param event event data
	 */
	storeTouchEnd(event: any) {
		if (event.changedTouches.length === 0) { return; }

		this._touchEnd = event.changedTouches[0].screenY;

		// If scroll is allowed to expand/collapse the card we process it
		if (this.isScrollAllowed) {
			this.processSwipeDirection();
		}
	}

	/**
	 * Hide Calling Area
	 * @param hideCallingArea
	 */
	toggleCallingArea(hideCallingArea: boolean) {
		this.hideCallingArea = hideCallingArea;
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	 */

	/**
	 * ================================================================================================================================
	 * Get Ticket Information
	 * ================================================================================================================================
	 */

	/**
	 * Gets the ticket information
	 *  - Get the card information from the route
	 * 		> Ticket - get the ticket unique id and with that obtains from the server the
	 * 					respective information (using signalR)
	 * 		> Booking - get the booking code and get the respective booking details from the
	 * 					server (if a ticket unique id exists we must activate the signalRs)
	 *      > If no ticket unique id or booking code is passed redirect to the error page.
	 */
	private getCardInformation(): void {
		// Watch for Ticket Tracker State change
		this.watchForTicketTrackerStateChanges();

		// Subscribe parameters for getting identification
		this._routerSub = this._activatedRoute.params.subscribe(params => {

			// Set the debug mode and preview mode if they exist
			const debugMode =
				!isNullOrUndefined(this._router.routerState.snapshot.root.queryParams) &&
					!isNullOrUndefined(this._router.routerState.snapshot.root.queryParams.debug) &&
					this._router.routerState.snapshot.root.queryParams.debug === '1' ? true : false;

			// Default
			if (isNullOrUndefined(params.ticketuniqueid) && isNullOrUndefined(params.bookingcode)) {
				// Ticket Unique Id not passed
				this._logger.warn(`${this.componentName}:getCardInformation`, 'Ticket Unique Id or Booking Code doesn\'t exist !');

				// Goto error page
				this.gotoErrorPage(TicketTrackerErrors.TicketNotFound);

			} else if (isNullOrUndefined(params.servicetype)) {

				this._logger.info(`${this.componentName}:getCardInformation`, 'Ticket Unique Id', params.ticketuniqueid,
					'Debug Mode?', debugMode);

				// Get ticket information
				this._ticketTrackerService.getTicketInformation(params.ticketuniqueid, debugMode);

			} else if (parseInt(params.servicetype, 10) === ServiceTypeEnum.Booking) {
				// eslint-disable-next-line @typescript-eslint/no-inferrable-types
				const isControlDigit: boolean = !NumberUtilities.isValidGuid(params.bookingcode);

				this._logger.info(`${this.componentName}:getCardInformation`, 'Booking Code', params.bookingcode,
					'Is Control Digit Only?', isControlDigit, 'Debug Mode?', debugMode);

				//+ If we have only the control digit, it means we must ask for customer validation to validate
				//+ if booking is of the customer
				//+ Added by Carlos.Moreira @ 2022/11/29
				if (isControlDigit) {

					// Get Page info
					const validatePage = ['validate-booking', params.bookingcode];

					// Navigate to Validation Page
					this._router.navigate(validatePage, { relativeTo: this._activatedRoute.root })
						.then(
							value => {
								this._logger.debug(`${this.componentName}:goToPage`, `Navigated '${validatePage.join('/')}' ?`, value);
							},
							error => {
								this._logger.error(`${this.componentName}:goToPage`, `Navigated '${validatePage.join('/')}' ? `, error);
							});

				} else {
					// Get the booking code and update ticket tracker state
					this._ticketTrackerService.bookings.getBookingDetailByCode(params.bookingcode)
						.subscribe(
							(bookingInfo: BookingDetails) => {
								this._ticketTrackerService.ticketTrackerState = {
									status: !isNullOrUndefined(bookingInfo.ticketUniqueId) && bookingInfo.statusId !== BookingStatusEnum.Finished ?
										TicketTrackerStatus.StartItemTracking : TicketTrackerStatus.ShowItem,
									bookingInfo: bookingInfo,
									ticketUniqueId: bookingInfo.ticketUniqueId
								};
							}
						);
				}
			}
		});
	}

	/**
	 * ================================================================================================================================
	 * Ticket Tracker Changes
	 * ================================================================================================================================
	 */

	/**
	 * Watches for ticket tracker state changes and acts accordingly
	 */
	private watchForTicketTrackerStateChanges() {
		// Watch for ticket tracker changes
		this._ticketTrackerStateSub = this._ticketTrackerService.ticketTrackerState$.pipe(
			distinctUntilChanged(),
			filter(ticketTrackerState =>
				!isNullOrUndefined(ticketTrackerState) && ticketTrackerState.status !== TicketTrackerStatus.ItemCalledToPanel))
			.subscribe((ticketTrackerState: TicketTrackerState) => {

				// Ticket Tracker State changed
				this._logger.debug(`${this.componentName}:watchForTicketTrackerStateChanges`,
					'Ticket Tracker Status', TicketTrackerStatus[ticketTrackerState.status], 'State', ticketTrackerState);

				switch (ticketTrackerState.status) {
					case TicketTrackerStatus.StartItemTracking:
						// Save new ticket tracker current status ... only ... nothing more here
						this._currentState = ticketTrackerState.status;
						break;

					case TicketTrackerStatus.ShowAndTrackItem:
					case TicketTrackerStatus.ShowItem:
						// Save new ticket tracker current status
						this._currentState = ticketTrackerState.status;

						//+ If we are in state ShowAndTrackItem and notifications aren't yet initialize and we
						//+ have a ticketUniqueId, means that we need to connect to notification server and get
						//+ tickets notifications
						//+ Use cases:
						//+	- after clicking in a booking in my-items
						//+	- after a check-in
						//+ Added by Carlos.Moreira @ 2022/12/02
						if (ticketTrackerState.status === TicketTrackerStatus.ShowAndTrackItem &&
							!ticketTrackerState.isInitialize && !isNullOrUndefined(ticketTrackerState.ticketUniqueId)) {
							// Get ticket information
							this._ticketTrackerService.getTicketInformation(ticketTrackerState.ticketUniqueId);
						}

						// Assign custom settings (if exist)
						// Added by Carlos.Moreira @ 2019/07/26
						if (!isNullOrUndefined(ticketTrackerState.entitySettings?.settings)) {
							const ticketTrackerSettings: TicketTrackerSettings =
								(ticketTrackerState.entitySettings.settings as EntityAppsSettings).ticketTrackerSettings;

							// Show counter in calling panels (entity settings) ?
							this.showCounterInCallingPanels =
								isNullOrUndefined(ticketTrackerSettings?.tickets?.showCounterInCallingPanels) ? true :
									ticketTrackerSettings.tickets.showCounterInCallingPanels;

							// ETS Display (entity settings override)
							// Added by Carlos.Moreira @ 2020/02/26
							this.showETS = isNullOrUndefined(ticketTrackerSettings?.tickets?.showETS) ?
								EtsDisplayType.Auto : ticketTrackerSettings.tickets.showETS;

						} else {
							// If no custom settings are passed assume defaults
							this.showCounterInCallingPanels = true;
							this.showETS = EtsDisplayType.Auto;

						}

						// Validate if scroll is allowed
						if (!isNullOrUndefined(ticketTrackerState.bookingInfo)
							&& (ticketTrackerState.bookingInfo.statusId !== BookingStatusEnum.Present
								&& ticketTrackerState.bookingInfo.statusId !== BookingStatusEnum.Called)) {
							this.isScrollAllowed = false;
						}

						break;

					case TicketTrackerStatus.TicketNotFound:
						// Goto error page
						this.gotoErrorPage(TicketTrackerErrors.TicketNotFound);

						break;

					case TicketTrackerStatus.BookingNotFound:
						// Goto error page
						this.gotoErrorPage(TicketTrackerErrors.BookingNotFound);

						break;

					case TicketTrackerStatus.ItemCalled:
						// Indicates to the card the item is called
						//+ now by reverting the variable we can guarantee that is received by card
						//+ Modified by Carlos.Moreira @ 2022/12/16
						this.isItemCalled = !this.isItemCalled;

						// Forces the card to be expanded when called
						// Added by Carlos.Moreira @ 2019/09/11
						this.isScrollingUp = true;

						break;
				}

				// Save new app current status (if not ticket called)
				this._currentState = ticketTrackerState.status === TicketTrackerStatus.ItemCalled ?
					this._currentState : ticketTrackerState.status;
			});
	}

	/**
	 * ================================================================================================================================
	 * Errors
	 * ================================================================================================================================
	 */

	/**
	 * Goes to a specific error page (by default goes to the Out-Of-Order page)
	 * @param errorCode error code
	 * @param errorMessage error message
	 */
	private gotoErrorPage(errorCode?: number, errorMessage?: string) {
		// By default if nothing is passed, show cannot get dispenser configuration error code
		let errorPage: any[] = ['error', TicketTrackerErrors.TicketNotFound, ''];

		this._logger.debug(`${this.componentName}:gotoErroPage`, 'Error Code', errorCode, 'Error Message', errorMessage);

		// if error and message is passed use them
		if (!isNullOrUndefined(errorCode)) {
			errorPage = ['./error', errorCode, isNullOrUndefined(errorMessage) ? '' : errorMessage];
		}

		// Navigate to error page
		this._router.navigate(errorPage, { relativeTo: this._activatedRoute.root })
			.then(
				value => {
					this._logger.debug(`${this.componentName}:gotoErrorPage`, `Navigated '${errorPage.join('/')}' ?`, value);
				},
				error => {
					this._logger.error(`${this.componentName}:gotoErrorPage`, `Navigated '${errorPage.join('/')}' ? `, error);
				});
	}

	/**
	 * ================================================================================================================================
	 * Swipe
	 * ================================================================================================================================
	 */

	/**
	 * Process swipes movement on screen
	 */
	private processSwipeDirection() {
		// If swipe is very small or touch events had to be stopped, exit
		if (((this._touchStart - this._touchEnd) < 50 && (this._touchEnd - this._touchStart) < 50) || this._stopTouchEvents) { return; }

		// Detect direction
		this.isScrollingUp = this._touchStart > this._touchEnd ? false : true;
	}
}
